<template>
  <section>
    <div class="row">
      <div class="col-12 mb-2">
        <h1 class="h3">Lista de edificios</h1>
      </div>

      <!-- Filter card -->
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <b-form-group
                class="col-md-3"
                label-size="sm"
                label="Denominación"
                label-for="tableDenominacion"
              >
                <b-form-input
                  id="tableDenominacion"
                  type="text"
                  v-model="filter.denominacion"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="row">
              <b-form-group
                class="col-md-3"
                label-size="sm"
                label="Uso"
                label-for="tableUso"
              >
                <b-form-select
                  id="tableUso"
                  :options="usoArray"
                  v-model="filter.uso"
                >
                  <template #first>
                    <option value="">------</option>
                  </template>
                </b-form-select>
              </b-form-group>

              <b-form-group
                label="Zona climática"
                label-for="tableZona"
                class="col-md-3"
                label-size="sm"
              >
                <b-form-select
                  id="tableZona"
                  :options="zonaClimaticaArray"
                  v-model="filter.zonaClimatica"
                >
                  <template #first>
                    <option value="">------</option>
                  </template>
                </b-form-select>
              </b-form-group>

              <b-form-group
                label="Fecha de construcción"
                label-for="tableFecha"
                class="col-md-3"
                label-size="sm"
              >
                <b-form-select
                  id="tableFecha"
                  :options="fechaConstruccionArray"
                  v-model="filter.yearInterval"
                >
                  <template #first>
                    <option value="">------</option>
                  </template>
                </b-form-select>
              </b-form-group>

              <!-- <b-form-group
                label="Superficie habitable"
                label-for="tableSuperficie"
                class="col-md-3"
                label-size="sm"
              >
                <b-form-input
                  id="tableSuperficie"
                  type="number"
                  step="0.01"
                  v-model="filter.superficieHabitable"
                >
                </b-form-input>
              </b-form-group> -->

              <b-form-group
                label="Configuración"
                label-for="tableConfiguracion"
                class="col-md-3"
                label-size="sm"
              >
                <b-form-select
                  id="tableConfiguracion"
                  :options="configuracionArray"
                  v-model="filter.configuracion"
                >
                  <template #first>
                    <option value="">------</option>
                  </template>
                </b-form-select>
              </b-form-group>
            </div>
            <div class="row">
              <div class="col-12 text-right">
                <button class="btn btn-primary mr-2" @click="getFilteredItems">
                  Filtrar
                </button>
                <button class="btn btn-terciary" @click="compareBuildings">
                  Comparar edificios
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- . Filter card -->

      <!-- Items card -->
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <b-table
              :per-page="perPage"
              :current-page="currentPage"
              responsive
              striped
              hover
              :fields="fields"
              :items="items"
            >
              <template v-slot:head(#)="row">
                <span class="hidden">{{ row }}</span>
              </template>
              <template v-slot:cell(#)="row">
                <b-form-group>
                  <input
                    type="checkbox"
                    v-model="checkedItems"
                    :value="row.item"
                  />
                </b-form-group>
              </template>
              <template v-slot:cell(accion)="row">
                <b-form-group>
                  <button
                    v-show="isOwner(row.item)"
                    class="btn btn-primary"
                    @click="edit(row.item.id)"
                  >
                    Editar
                  </button>
                </b-form-group>
              </template>
              <template #cell(denominacion)="data">
                <router-link
                  :to="{ name: `details`, params: { id: data.item.id } }"
                >
                  {{ data.item.denominacion }}
                </router-link>
              </template>
            </b-table>

            <!-- Pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              first-text="First"
              prev-text="Prev"
              next-text="Next"
              last-text="Last"
              pills
              align="center"
              class="mt-5"
            ></b-pagination>
            <!--. Pagination -->
          </div>
        </div>
      </div>
      <!-- . Items card -->
    </div>
  </section>
</template>

<script>
import api from "@/api.js";
import store from "@/store";
export default {
  name: "ListBuildings",
  data: () => ({
    items: [],
    filteredItems: [],
    checkedItems: [],
    currentPage: 1,
    perPage: 10,
    fields: [
      "#",
      "accion",
      {
        key: "denominacion",
        label: "Denominación",
      },
      {
        key: "localidad",
        label: "Localidad",
      },
      {
        key: "uso",
        label: "Uso",
      },
      {
        key: "zona_climatica",
        label: "Zona",
      },
      {
        key: "construccion_year",
        label: "Año",
      },
      {
        key: "superficie_habitable",
        label: "Superficie",
      },
      {
        key: "configuracion",
        label: "Configuración",
      },
    ],

    filter: {
      denominacion: "",
      uso: "",
      zonaClimatica: "",
      yearInterval: "",
      minYear: "",
      maxYear: "",
      superficieHabitable: "",
      configuracion: "",
    },

    usoArray: [
      {
        value: "DOCENTE",
        text: "Docente",
      },
      {
        value: "ADMINISTRATIVO",
        text: "Administrativo",
      },
      {
        value: "SANITARIO",
        text: "Sanitario",
      },
      {
        value: "DEPORTIVO",
        text: "Deportivo",
      },
      {
        value: "OCIO / ESPECTÁCULO",
        text: "Ocio / Espectáculo",
      },
      {
        value: "COMERCIAL",
        text: "Comercial",
      },
      {
        value: "INDUSTRIAL",
        text: "Industrial",
      },
    ],

    zonaClimaticaArray: [
      "A4",
      "A3",
      "B4",
      "B3",
      "C4",
      "C3",
      "C2",
      "C1",
      "D3",
      "D2",
      "D1",
      "E1",
    ],

    fechaConstruccionArray: ["<1900", "1900-1949", "1950-2000", ">2000"],

    configuracionArray: [
      {
        value: "EDIFICO COMPLETO ENTREMEDIANERO",
        text: "Edificio completo entremedianero",
      },
      {
        value: "EDIFICO COMPLETO EXENTO",
        text: "Edificio completo exento",
      },
      {
        value: "LOCAL O APARTAMENTO",
        text: "Local o apartamento",
      },
    ],
  }),
  created() {
    this.getBuildings();
  },

  computed: {
    rows() {
      return this.items.length;
    },
  },

  methods: {
    async getBuildings() {
      const { data } = await api.get("buildings/");
      this.items = data;
    },

    edit(id) {
      this.$router.push({
        name: "building",
        params: { id },
      });
    },
    details(id) {
      this.$router.push({
        name: `details`,
        params: { id },
      });
    },

    parseDates(interval) {
      if (interval === "<1900") {
        this.filter.minYear = "";
        this.filter.maxYear = 1899;
      } else if (interval === "1900-1949") {
        this.filter.minYear = 1900;
        this.filter.maxYear = 1949;
      } else if (interval === "1950-2000") {
        this.filter.minYear = 1950;
        this.filter.maxYear = 2000;
      } else if (interval === ">2000") {
        this.filter.minYear = 2001;
        this.filter.maxYear = "";
      } else {
        this.filter.minYear = "";
        this.filter.maxYear = "";
      }
    },

    async getFilteredItems() {
      this.parseDates(this.filter.yearInterval);
      const { data } = await api.get(
        `buildings/?uso=${this.filter.uso}&zona_climatica=${this.filter.zonaClimatica}&min_year=${this.filter.minYear}&max_year=${this.filter.maxYear}&superficie_habitable=${this.filter.superficieHabitable}&configuracion=${this.filter.configuracion}`
      );

      if (this.filter.denominacion) {
        let filteredByDenom = [];
        data.forEach((item) => {
          if (
            item.denominacion
              .toUpperCase()
              .includes(this.filter.denominacion.toUpperCase())
          ) {
            filteredByDenom.push(item);
          }
        });
        this.items = filteredByDenom;
      } else {
        this.items = data;
      }

      // await this.addSelectedField();
    },

    async compareBuildings() {
      let ids = this.checkedItems.map((item) => item.id);

      if (ids.length === 0) {
        ids = this.items.map((item) => item.id);
      }
      this.$router.push({
        name: "compareBuildings",
        query: { buildingIds: ids },
      });

      // await api.post(`compare_buildings/`, {
      //   building_ids: ids,
      // });
    },

    isOwner(building) {
      const isOwner = building.creator
        ? store.getters["auth/getUserEmail"] === building.creator.email
        : false;
      return isOwner;
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  margin-left: 10px;
}

.list {
  margin: 50px;
}
.filter-button-margin {
  margin-top: 2.4rem;
}
</style>
